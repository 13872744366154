
  
  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #13161a;
  
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  
  }