@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Inter:wght@600&display=swap');
*{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height:100vh;
  
}
h2{
font-family: 'Inter', sans-serif;
}
p{ 
font-family: 'Dosis', sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: 
  #ff1744;
  border-radius: 3px;
}

code {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}